




















import allData from '../carddata.json';
const relicsData: any[] = allData.relics;

import Vue from 'vue';
import RunDetailTitle from './RunDetailTitle.vue';
export default Vue.extend({
  name: 'runRelics',
  components: { RunDetailTitle },
  props: {
    relics: {
      type: Array,
      required: true,
    },
    relicsObtained: {
      type: Array,
      required: true,
    },
  },
  computed: {
    findOrCreateRelicObjects(): Array<{}> {
      const relicsArray: any = [];
      for (const relic of this.relics as string[]) {
        const relicNameFormatted = this.nameModifier(relic);
        let relicObj = relicsData.find((rel: any) => this.camelise(rel.name) === this.camelise(relicNameFormatted));
        if (relicObj === undefined) {
          relicObj = {
            name: relic,
            unknown: true,
          };
        }
        relicsArray.push(relicObj);
      }
      return relicsArray;
    },
  },
  methods: {
    nameModifier(name: string) {
      const namesToConvert: string[][] = [
        // [Old Name, Current Name]
        ['Frozen Egg 2', 'Frozen Egg'],
        ['Neows Blessing', `Neow's Blessing`],
        ['Boot', 'The Boot'],
      ];
      for (const nameToConvert of namesToConvert) {
        if (name === nameToConvert[0]) {
          return nameToConvert[1];
        }
      }
      return name;
    },
    relicRatityCountsObject(): object {
      const relicRarityCount: any = {};
      for (const relic of this.relics as string[]) {
        let relicObj = relicsData.find((rel: any) => this.camelise(rel.name) === this.camelise(relic));
        if (relicObj === undefined) {
          console.log('undefined item');
          relicObj = {
            rarity: 'unknown',
          };
        }
        if (relicObj.rarity in relicRarityCount) {
          relicRarityCount[relicObj.rarity] += 1;
        } else {
          relicRarityCount[relicObj.rarity] = 1;
        }
      }
      return relicRarityCount;
    },
    getImgUrl(url: string) {
      const images = require.context('../../assets/', true, /\.png$/);
      return images('./' + url + '.png');
    },
    relicImg(title: string): string {
      const camelised = this.camelise(title);
      return `relics/${camelised}`;
    },
    camelise(str: string): string {
      str = str.split('.').join('');
      str = str.split(`'`).join('');
      return str
        .replace(/(?:^\w|[A-Z\.]|\b\w)/g, (letter, index) => {
          return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, '');
    },
    relicCorrectedName(name: string): string {
      return name.replace(/[A-Z]/g, ' $&');
    },
  },
});
