






import Vue from 'vue';
export default Vue.extend({
  name: 'runDetailTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    textDetailCountsObject: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ratityCountsString() {
      // const this.textDetailCountsObject = this.relicRatityCounts();
      let str = '';
      if ('boss' in this.textDetailCountsObject) {
        str += `${this.textDetailCountsObject.boss} Boss`;
      }
      if ('special' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.special} Special`;
      }
      if ('rare' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.rare} Rare`;
      }
      if ('event' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.event} Event`;
      }
      if ('shop' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.shop} Shop`;
      }
      if ('uncommon' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.uncommon} Uncommon`;
      }
      if ('common' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.common} Common`;
      }
      if ('starter' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.starter} Starter`;
      }
      if ('unknown' in this.textDetailCountsObject) {
        if (str.length > 0) {
          str += ', ';
        }
        str += `${this.textDetailCountsObject.unknown} Unknown`;
      }
      return str;
    },
  },
});
