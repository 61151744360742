































import runRelics from './relics.vue';
import runCards from './cards.vue';
import runPath from './path.vue';
import examplerundata from '../examplerun.json';
import loading from '../../../../components/loading.vue';

import gql from 'graphql-tag';
import RunFragment from '../../../../graphql/RunFragment.gql';

import Vue from 'vue';
export default Vue.extend({
  components: { runPath, runRelics, runCards, loading },
  data() {
    return {
      // character_chosen: 'THE_IRONCLAD',
      // score: 1367,
      // is_ascension_mode: true,
      // ascension_level: 3,
      // victory: false,
      // killed_by: 'Shelled Parasite and Fungi',
      // floor_reached: 30,
      // seed: '8383nodw1dunui80dp7',
      spirespyGetRun: {},
    };
  },
  apollo: {
    // Query with parameters
    spirespyGetRun: {
      // gql query
      query: gql`query($id: String) {
        spirespyGetRun(id: $id) {
          ... Run
        }
      },
      ${RunFragment}`,
      // Static parameters
      variables() {
        return {
          // @ts-ignore
          id: this.$route.params.id as string,
        };
      },
      // update: (data) => data.totalruns,
    } as {},
  },
  computed: {
    victorySlainString(): string {
      let vicStr = '';
      if (this.isVictory()) {
        vicStr += 'Victory! ';
      } else {
        // @ts-ignore
        vicStr += `Slain on Floor ${this.spirespyGetRun.floor_reached} by ${this.spirespyGetRun.killed_by} `;
      }
      // @ts-ignore
      if (this.spirespyGetRun.is_ascension_mode) {
                  // @ts-ignore
        vicStr += `(Ascension Level: ${this.spirespyGetRun.ascension_level})`;
      }
      return vicStr;
    },
  },
  methods: {
    isVictory(): boolean {
      // @ts-ignore
      return this.spirespyGetRun.victory;
    },
    isAscentionMode(): boolean {
                        // @ts-ignore
      return this.spirespyGetRun.isAscensionMode;
    },
    characterName(): string {
      // @ts-ignore
      let charStr = this.spirespyGetRun.character_chosen;
      charStr = charStr.replace('THE_', ' ');
      return charStr = charStr.replace('_', ' ').toLowerCase();
    },
    runDateString(): string {
      // @ts-ignore
      const date = new Date(this.spirespyGetRun.timestamp * 1000);
      const month = date.getMonth(); // zero index
      const monthsArray = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const monthWord = monthsArray[month].toUpperCase();
      const day = date.getUTCDate();
      const year = date.getUTCFullYear();

      const newdate = day + ' ' + monthWord + ' ' + year;
      return newdate;
    },
    getDataOnFail() {
      this.$apollo.queries.spirespyGetRun.refetch();
      setTimeout(() => {
        // failed gQL converts the null to undefined!!
        if (this.spirespyGetRun === undefined) {
          this.getDataOnFail();
        }
      }, 5000);
    },
  },
  mounted() {
    console.log(this.spirespyGetRun === null);
    if (this.spirespyGetRun === null) {
      setTimeout(() => {
        this.getDataOnFail();
      }, 5000);
    }
  },
});
