















































import Vue from 'vue';
export default Vue.extend({
  name: 'runPath',
  props: {
    path_taken: {
      type: Array as () => string[],
      required: true,
    },
    path_per_floor: {
      type: Array as () => string[],
    },
  },
  computed: {
    pathData(): Array<{}> {
      const paths: Array<{}> = [];
      for (const path of this.path_taken as [string]) {
        const obj = {
          // type: this.convertType(path),
          type: path,
        };
        paths.push(obj);
      }
      return paths;
    },
    compiledPathData() {
      const pathDataArray: Array<{}> = [];
      // console.log('---------');
      // compile paths
        // remove null objects

      // for (const [index, path] of this.path_per_floor.entries()) {
      //   const obj: any = {};

      //   console.log(path);
      //   console.log(this.convertType(path));

      //   if (
      //     path === null &&
      //     (index === 16 || index === 32)
      //   ) {
      //     console.log('null');
      //     obj.path = 'bossChest';
      //   } else {
      //     obj.path = this.convertType(path);
      //   }
      //   pathDataArray.push(obj);
      // }
      // console.log('---------');

      return true;
    },
    rawPathsNoNuLLs(): {nullFilteredPath: string[], nullIndexArray: number[]} {
      const nullIndexArray: number[] = [];
      const nullFilteredPath: string[] = this.path_per_floor.filter((path: string, index: number) => {
        if (path === null) {
          nullIndexArray.push(index);
          return false;
        }
        return this.convertType(path);
      });
      console.log(nullFilteredPath);
      return {
        nullFilteredPath,
        nullIndexArray,
      };
    },
    convertPathTypesNoNulls(): string[] {
      const convertedData = [];
      // uses path_taken to find ? (unknowns)
      for (const [index, path] of this.path_taken.entries()) {
        let pathVar = this.convertType(path);
        if (pathVar === 'unknown') {
          pathVar = this.unknownKnown(index);
        }
        convertedData.push(pathVar);
      }
      return convertedData;
    },
    convertedPathsConvertedNullsAdded(): string[] {
      const convertedData: string[] = [];
      this.convertPathTypesNoNulls.forEach((element) => {
        convertedData.push(element);
      });
      this.rawPathsNoNuLLs.nullIndexArray.forEach((index) => {
        if (index === 16 || index === 33) {
          convertedData.splice(index, 0, 'bossChest');
        }
        if (index === 56) {
          convertedData.splice(index, 0, 'unknown');
        }
      });
      if (convertedData[50]) {
        convertedData[50] = 'unknown';
      }
      return convertedData;
    },

  },
  methods: {
    unknownKnown(index: number): string {
      // needs to use rawpathnonulls for matching
      if (this.convertType(this.rawPathsNoNuLLs.nullFilteredPath[index]) === 'shop') {
        return 'unknownShop';
      }
      if (this.convertType(this.rawPathsNoNuLLs.nullFilteredPath[index]) === 'monster') {
      return 'unknownMonster';
      }
      if (this.convertType(this.rawPathsNoNuLLs.nullFilteredPath[index]) === 'chest') {
      return 'unknownChest';
      }
      return 'unknown';
    },
    mapPathTakenToPathPerFloor(index: number): string {
      // if (index < 16) {
      // }
      return this.unknownKnown(index);

    },
    convertType(type: string) {
      if (type === 'M') {
        return 'monster';
      }
      if (type === '$') {
        return 'shop';
      }
      if (type === '?') {
        return 'unknown';
      }
      if (type === 'R') {
        return 'rest';
      }
      if (type === 'E') {
        return 'elite';
      }
      if (type === 'T') {
        return 'chest';
      }
      if (type === 'BOSS') {
        return 'boss';
      }
      // console.log(type);
      return type;
    },
    getImgUrl(url: string) {
      const images = require.context('../../assets/runs/path/', true, /\.png$/);
      return images('./' + url + '.png');
    },
  },
});
