




















































import allData from '../carddata.json';
const cardsData: any[] = allData.cards;
import card from '../cardv2.vue';

import Vue from 'vue';
import RunDetailTitle from './RunDetailTitle.vue';
export default Vue.extend({
  name: 'runCards',
  components: { RunDetailTitle, card },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    cardNames: {
      type: Array,
      required: true,
    },
    character: {
      type: String,
      required: true,
    },
    // cardsObtained: {
    //   type: Array,
    //   required: true,
    // },
  },
  computed: {
    findOrCreateCardObjects(): Array<{}> {
      const cardsArray: any = [];
      for (const cardName of this.cards as string[]) {
        // console.log('-----------');
        // console.log(cardName);

        let cardNameFormatted = cardName;

        let cardPlus = 0;
        // console.log(cardNameFormatted, ' is upgraded?: [', cardNameFormatted.match(/\+\d+/g), ']');
        const hasUpgrade = cardNameFormatted.match(/\+\d+/g);
        if (hasUpgrade) {
          const upgradeValue = hasUpgrade.toString().replace('+', '');
          cardPlus = parseInt(upgradeValue, 10);
          cardNameFormatted = cardNameFormatted.replace(hasUpgrade.toString(), '');
        }
        if (cardNameFormatted === 'Defend_R' ||
            cardNameFormatted === 'Defend_G' ||
            cardNameFormatted === 'Defend_B' ||
            cardNameFormatted === 'Defend_P'
        ) {
          cardNameFormatted = 'Defend';
        }
        if (cardNameFormatted === 'Strike_R' ||
            cardNameFormatted === 'Strike_G' ||
            cardNameFormatted === 'Strike_B' ||
            cardNameFormatted === 'Strike_P'
        ) {
          cardNameFormatted = 'Strike';
        }

        const hasVersion = cardNameFormatted.match(/v\d+/g);
        let cardVersion = 0;
        if (hasVersion) {
          console.log(cardNameFormatted, hasVersion);

          const versionValue = hasVersion.toString().replace('+', '');
          cardVersion = parseInt(versionValue, 10);
          cardNameFormatted = cardNameFormatted.replace(hasVersion.toString(), '');
        }

        console.log(cardNameFormatted);
        cardNameFormatted = this.nameModifier(cardNameFormatted);
        console.log(cardNameFormatted);

        const getCardObjData = cardsData.find((rel: any) =>
          this.camelise(rel.name) === this.camelise(cardNameFormatted));
        // console.log(getCardObjData);

        let cardObj;
        if (getCardObjData === undefined) {
          cardObj = {
            name: cardNameFormatted,
            unknown: true,
          };
        } else {
          const cardObjString = JSON.stringify(
            // to clone a new object
            getCardObjData,
          );
          console.log(cardObjString);

          cardObj = JSON.parse(cardObjString);
          cardObj.unknown = false;
        }
        // this.$set(cardObj, 'plus', cardPlus);
        if (
          cardObj.name === 'Strike' ||
          cardObj.name === 'Defend'
        ) {
          cardObj.character = this.character;
        }
        cardObj.plus = cardPlus;
        cardObj.version = cardVersion;
        console.log(cardObj);

        cardsArray.push(cardObj);
      }

      return cardsArray;
    },
  },
  methods: {
    cardCharacter(character: string) {
      if (
        character === 'ironclad' ||
        character === 'silent' ||
        character === 'defect' ||
        character === 'watcher' ||
        character === 'colorless' ||
        character === 'curse'
      ) {
        return character;
      }
      return undefined;
    },
    // cardRarity(rarity: string) {
    //   if (
    //     rarity === 'rare' ||
    //     rarity === 'uncommon'
    //   ) {
    //     return rarity;
    //   }
    //   return undefined;
    // },
    nameModifier(name: string) {
      const namesToConvert: string[][] = [
        // [Old Name, Current Name]
        // Cards
        // Ironclad
        // Silent
        ['Well Laid Plans', 'Well-Laid Plans'],
        ['Underhanded Strike', 'Sneaky Strike'],
        // Defect
        ['Lockon', 'Bullseye'],
        ['Conserve Battery', 'Charge Battery'],
        ['Turbo', 'TURBO'],
        ['Undo', 'Equilibrium'],

        // Watcher
        ['Path To Victory', 'Pressure Points'],
        ['Wireheading', 'Foresight'],

        // Relics
      ];
      for (const nameToConvert of namesToConvert) {
        if (name === nameToConvert[0]) {
          return nameToConvert[1];
        }
      }
      return name;
    },
    cardRatityCountsObject(): object {
      const cardRarityCount: any = {};
      let cardPlus = false;
      for (const cardItem of this.cards as string[]) {
        console.log(cardItem);
        let cardName = cardItem;
        if (cardName === 'Defend_P') {
          cardName = cardName.replace('Defend_P', 'Defend');
        }
        if (cardName.indexOf('+1')) {
          // cardPlus = true;
          cardName = cardName.replace('+1', '');
        }
        // console.log(cardName);

        let cardObj = cardsData.find((car: any) => this.camelise(car.name) === this.camelise(cardName));
        // console.log(cardObj);
        // if (cardObj) {
        //   cardObj = {
        //     name: card,
        //     official: false,
        //   };
        // }
        // if (cardPlus) {
        //   cardObj.plus = true;
        // }
        if (cardObj === undefined) {
          // console.log('hihihi');
          cardObj = { rarity: 'unknown' };
        }
        if (cardObj.rarity in cardRarityCount) {
          cardRarityCount[cardObj.rarity] += 1;
        } else {
          cardRarityCount[cardObj.rarity] = 1;
        }
      }
      return cardRarityCount;
    },
    camelise(str: string): string {
      str = str.split('.').join('');
      str = str.split(`'`).join('');
      return str
        .replace(/(?:^\w|[A-Z\.]|\b\w)/g, (letter, index) => {
          return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, '');
    },
    // relicRatityCountsObject(): object {
    //   const relicRarityCount: any = {};
    //   for (const relic of this.relics as string[]) {
    //     const relicObj = relicsData.find((rel: any) => this.camelise(rel.name) === this.camelise(relic));
    //     if (relicObj.rarity in relicRarityCount) {
    //       relicRarityCount[relicObj.rarity] += 1;
    //     } else {
    //       relicRarityCount[relicObj.rarity] = 1;
    //     }
    //   }
    //   return '4 boss cards';
    //   return relicRarityCount;
    // },
  },
});
